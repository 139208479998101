<template>
    <ol class="timeline">
        <li v-for="(item, index) in timeline" :key="index"
            :class="{'timeline-item':true, 'extra-space': item.type === 'mail'}">
            <span class="timeline-item-icon filled-icon" v-if="item.type === 'mail'">
                <font-awesome-icon icon="envelope"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else-if="item.type === 'comment'">
                <font-awesome-icon icon="comment"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else-if="item.type === 'state'"
                  :class="'bg-' + stateInfo(item.state).color">
                <font-awesome-icon :icon="stateInfo(item.state).icon"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else-if="item.type === 'assignment'" :class="'bg-secondary'">
                <font-awesome-icon icon="user"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else-if="item.type === 'item_relation'">
                <font-awesome-icon icon="object-group"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else-if="item.type === 'shipping_voucher'">
                <font-awesome-icon icon="truck"/>
            </span>
            <span class="timeline-item-icon faded-icon" v-else>
                <font-awesome-icon icon="pen"/>
            </span>
            <TimelineMail v-if="item.type === 'mail'" :item="item"/>
            <TimelineComment v-else-if="item.type === 'comment'" :item="item"/>
            <TimelineStateChange v-else-if="item.type === 'state'" :item="item"/>
            <TimelineAssignment v-else-if="item.type === 'assignment'" :item="item"/>
            <TimelineRelatedItem v-else-if="item.type === 'item_relation'" :item="item"/>
            <TimelineShippingVoucher v-else-if="item.type === 'shipping_voucher'" :item="item"/>
            <p v-else>{{ item }}</p>
        </li>
        <li class="timeline-item">
            <span class="timeline-item-icon | faded-icon">
                <font-awesome-icon icon="comment"/>
            </span>
            <div class="new-comment card bg-dark">
                <div class="">
                    <textarea placeholder="add comment..." v-model="newComment" class="form-control">
                    </textarea>
                    <AsyncButton class="btn btn-primary float-right" :task="addCommentAndClear">
                        <font-awesome-icon icon="comment"/>
                        Save Comment
                    </AsyncButton>
                </div>
            </div>
        </li>
        <li class="timeline-item">
            <span class="timeline-item-icon | faded-icon">
                <font-awesome-icon icon="envelope"/>
            </span>
            <div class="new-mail card bg-dark">
                <div class="card-header">
                    {{ newestMailSubject }}
                </div>
                <div>
                    <textarea placeholder="reply mail..." v-model="newMail" class="form-control">
                    </textarea>
                    <AsyncButton class="btn btn-primary float-right" :task="sendMailAndClear">
                        <font-awesome-icon icon="envelope"/>
                        Send Mail
                    </AsyncButton>
                </div>
            </div>
        </li>
    </ol>
</template>

<script>

import TimelineMail from "@/components/TimelineMail.vue";
import TimelineComment from "@/components/TimelineComment.vue";
import TimelineStateChange from "@/components/TimelineStateChange.vue";
import {mapActions, mapGetters} from "vuex";
import TimelineAssignment from "@/components/TimelineAssignment.vue";
import TimelineRelatedItem from "@/components/TimelineRelatedItem.vue";
import TimelineShippingVoucher from "@/components/TimelineShippingVoucher.vue";
import AsyncButton from "@/components/inputs/AsyncButton.vue";

export default {
    name: 'Timeline',
    components: {
        TimelineShippingVoucher, AsyncButton,
        TimelineRelatedItem, TimelineAssignment, TimelineStateChange, TimelineComment, TimelineMail
    },
    props: {
        timeline: {
            type: Array,
            default: () => []
        }
    },
    emits: ['sendMail', 'addComment'],
    data: () => ({
        newMail: "",
        newComment: ""
    }),
    computed: {
        ...mapGetters(['stateInfo']),
        newestMailSubject() {
            const mail = this.timeline.filter(item => item.type === 'mail').pop();
            return mail ? mail.subject : "";
        },
    },
    methods: {
        ...mapActions(['fetchShippingVouchers']),
        sendMailAndClear: function () {
            this.$emit('sendMail', this.newMail);
            this.newMail = "";
        },
        addCommentAndClear: function () {
            this.$emit('addComment', this.newComment);
            this.newComment = "";
        }
    },
    mounted() {
        this.fetchShippingVouchers();
    }
};
</script>

<style lang="scss" scoped>

*,
*:before,
*:after {
    box-sizing: border-box;
}

button,
input,
select,
textarea {
    font: inherit;
}

a {
    color: inherit;
}

img {
    display: block;
    max-width: 100%;
}

/* End basic CSS override */

.timeline {
    width: 85%;
    display: flex;
    flex-direction: column;
    padding: 32px 0 32px 32px;
    border-left: 2px solid var(--gray);
    font-size: 1.125rem;
    margin: 0 auto;
}

.timeline-item {
    display: flex;
    gap: 24px;

    & + * {
        margin-top: 24px;
    }

    & + .extra-space {
        margin-top: 48px;
    }
}

.new-comment, .new-mail {
    width: 100%;

    textarea, input {
        border: 1px solid var(--gray);
        border-radius: 6px;
        height: 5em;
        padding: 8px 16px;

        &::placeholder {
            color: var(--gray-dark);
        }

        &:focus {
            border-color: var(--gray-dark);
            outline: 0; /* Don't actually do this */
            box-shadow: 0 0 0 4px var(--dark);
        }
    }
}

.timeline-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: -57px;
    flex-shrink: 0;
    overflow: hidden;

    svg {
        width: 20px;
        height: 20px;
    }

    &.faded-icon {
        background-color: var(--secondary);
        color: var(--light);
    }

    &.filled-icon {
        background-color: var(--primary);
        color: var(--light);
    }
}


.button {
    border: 0;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: 12px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 32px;
    padding: 0 8px;
    background-color: var(--dark);
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 99em;

    &:hover {
        background-color: var(--gray);
    }
}


</style>